"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'DepartTree',
  // 设置绑定参数
  model: {
    prop: 'value',
    event: 'selected'
  },
  props: {
    // 接收绑定参数
    value: String,
    // 输入框宽度
    width: String,
    // 选项数据
    options: {
      type: Array,
      required: true
    },
    // 输入框占位符
    placeholder: {
      type: String,
      required: false,
      default: '请选择'
    },
    // 树节点配置选项
    props: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          parent: 'parentId',
          value: 'rowGuid',
          label: 'areaName',
          children: 'children'
        };
      }
    }
  },
  data: function data() {
    return {
      // 树状菜单显示状态
      showStatus: false,
      // 菜单宽度
      treeWidth: 'auto',
      // 输入框显示值
      labelModel: '',
      // 实际请求传值
      valueModel: '0'
    };
  },
  computed: {
    // 是否为树状结构数据
    dataType: function dataType() {
      var jsonStr = JSON.stringify(this.options);
      return jsonStr.indexOf(this.props.children) !== -1;
    },
    // 若非树状结构，则转化为树状结构数据
    data: function data() {
      return this.dataType ? this.options : this.switchTree();
    }
  },
  watch: {
    labelModel: function labelModel(val) {
      if (!val) {
        this.valueModel = '';
      }
      this.$refs.tree.filter(val);
    },
    value: function value(val) {
      this.labelModel = this.queryTree(this.data, val);
    }
  },
  created: function created() {
    var _this = this;
    // 测评输入框原有值并显示对应 label
    if (this.value) {
      this.labelModel = this.queryTree(this.data, this.value);
    }
    // 获取输入框宽度同步至树状菜单宽度
    this.$nextTick(function () {
      _this.treeWidth = "".concat((_this.width || _this.$refs.input.$refs.input.clientWidth) - 24, "px");
    });
  },
  methods: {
    // 单击节点
    onClickNode: function onClickNode(node) {
      this.labelModel = node[this.props.label];
      this.valueModel = node[this.props.value];
      this.onCloseTree();
    },
    // 偏平数组转化为树状层级结构
    switchTree: function switchTree() {
      return this.cleanChildren(this.buildTree(this.options, '0'));
    },
    // 隐藏树状菜单
    onCloseTree: function onCloseTree() {
      this.$refs.popover.showPopper = false;
    },
    // 显示时触发
    onShowPopover: function onShowPopover() {
      this.showStatus = true;
      this.$refs.tree.filter(false);
    },
    // 隐藏时触发
    onHidePopover: function onHidePopover() {
      this.showStatus = false;
      this.$emit('selected', this.valueModel);
    },
    // 树节点过滤方法
    filterNode: function filterNode(query, data) {
      if (!query) return true;
      return data[this.props.label].indexOf(query) !== -1;
    },
    // 搜索树状数据中的 ID
    queryTree: function queryTree(tree, id) {
      var stark = [];
      stark = stark.concat(tree);
      while (stark.length) {
        var temp = stark.shift();
        if (temp[this.props.children]) {
          stark = stark.concat(temp[this.props.children]);
        }
        if (temp[this.props.value] === id) {
          return temp[this.props.label];
        }
      }
      return '';
    },
    // 将一维的扁平数组转换为多层级对象
    buildTree: function buildTree(data) {
      var _this2 = this;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0';
      var fa = function fa(parentId) {
        var temp = [];
        for (var i = 0; i < data.length; i++) {
          var n = data[i];
          if (n[_this2.props.parent] === parentId) {
            n.children = fa(n.rowGuid);
            temp.push(n);
          }
        }
        return temp;
      };
      return fa(id);
    },
    // 清除空 children项
    cleanChildren: function cleanChildren(data) {
      var fa = function fa(list) {
        list.map(function (e) {
          if (e.children.length) {
            fa(e.children);
          } else {
            delete e.children;
          }
          return e;
        });
        return list;
      };
      return fa(data);
    }
  }
};