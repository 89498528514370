"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _exam = require("@/api/paper/exam");
var _elementUi = require("element-ui");
var _ExamTimer = _interopRequireDefault(require("@/views/paper/exam/components/ExamTimer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ExamProcess',
  components: {
    ExamTimer: _ExamTimer.default
  },
  data: function data() {
    return {
      // 全屏/不全屏
      isFullscreen: false,
      showPrevious: false,
      showNext: true,
      loading: false,
      handleText: '交卷',
      pageLoading: false,
      // 测评ID
      paperId: '',
      // 当前答题卡
      cardItem: {},
      allItem: [],
      // 当前题目内容
      quData: {
        answerList: []
      },
      // 测评信息
      paperData: {
        leftSeconds: 99999,
        radioList: [],
        multiList: [],
        judgeList: []
      },
      // 单选选定值
      radioValue: '',
      // 多选选定值
      multiValue: [],
      // 已答ID
      answeredIds: []
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.paperId = id;
      this.fetchData(id);
    }
  },
  methods: {
    // 答题卡样式
    cardItemClass: function cardItemClass(answered, quId) {
      if (quId === this.cardItem.quId) {
        return 'warning';
      }
      if (answered) {
        return 'success';
      }
      if (!answered) {
        return 'info';
      }
    },
    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered: function countNotAnswered() {
      var notAnswered = 0;
      this.paperData.radioList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });
      this.paperData.multiList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });
      this.paperData.judgeList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });
      return notAnswered;
    },
    /**
     * 下一题
     */
    handNext: function handNext() {
      var index = this.cardItem.sort + 1;
      this.handSave(this.allItem[index]);
    },
    /**
     * 上一题
     */
    handPrevious: function handPrevious() {
      var index = this.cardItem.sort - 1;
      this.handSave(this.allItem[index]);
    },
    doHandler: function doHandler() {
      var _this = this;
      this.handleText = '正在交卷，请等待...';
      this.loading = true;
      var params = {
        id: this.paperId
      };
      (0, _exam.handExam)(params).then(function () {
        _this.$message({
          message: '测评提交成功，即将进入测评详情！',
          type: 'success'
        });
        _this.$router.push({
          name: 'ShowExam',
          params: {
            id: _this.paperId
          }
        });
      });
    },
    // 交卷操作
    handHandExam: function handHandExam() {
      var that = this;

      // 交卷保存答案
      this.handSave(this.cardItem, function () {
        var notAnswered = that.countNotAnswered();
        var msg = '确认要交卷吗？';
        if (notAnswered > 0) {
          msg = '您还有' + notAnswered + '题未作答，确认要交卷吗?';
        }
        that.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          that.doHandler();
        }).catch(function () {
          that.$message({
            type: 'info',
            message: '交卷已取消，您可以继续作答！'
          });
        });
      });
    },
    // 保存答案
    handSave: function handSave(item, callback) {
      var _this2 = this;
      if (item.id === this.allItem[0].id) {
        this.showPrevious = false;
      } else {
        this.showPrevious = true;
      }

      // 最后一个索引
      var last = this.allItem.length - 1;
      if (item.id === this.allItem[last].id) {
        this.showNext = false;
      } else {
        this.showNext = true;
      }
      var answers = this.multiValue;
      if (this.radioValue !== '') {
        answers.push(this.radioValue);
      }
      var params = {
        paperId: this.paperId,
        quId: this.cardItem.quId,
        answers: answers,
        answer: ''
      };
      (0, _exam.fillAnswer)(params).then(function () {
        // 必须选择一个值
        if (answers.length > 0) {
          // 加入已答列表
          _this2.cardItem.answered = true;
        }

        // 最后一个动作，交卷
        if (callback) {
          callback();
        }

        // 查找详情
        _this2.fetchQuData(item);
      });
    },
    // 测评详情
    fetchQuData: function fetchQuData(item) {
      var _this3 = this;
      // 打开
      var loading = _elementUi.Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // 获得详情
      this.cardItem = item;

      // 查找下个详情
      var params = {
        paperId: this.paperId,
        quId: item.quId
      };
      (0, _exam.quDetail)(params).then(function (response) {
        console.log(response);
        _this3.quData = response.data;
        _this3.radioValue = '';
        _this3.multiValue = [];

        // 填充该题目的答案
        _this3.quData.answerList.forEach(function (item) {
          if ((_this3.quData.quType === 1 || _this3.quData.quType === 3) && item.checked) {
            _this3.radioValue = item.id;
          }
          if (_this3.quData.quType === 2 && item.checked) {
            _this3.multiValue.push(item.id);
          }
        });

        // 关闭详情
        loading.close();
      });
    },
    // 测评详情
    fetchData: function fetchData(id) {
      var _this4 = this;
      var params = {
        id: id
      };
      (0, _exam.paperDetail)(params).then(function (response) {
        // 测评内容

        _this4.paperData = response.data;
        console.log(_this4.paperData);
        // 获得第一题内容
        if (_this4.paperData.radioList && _this4.paperData.radioList.length > 0) {
          _this4.cardItem = _this4.paperData.radioList[0];
        } else if (_this4.paperData.multiList && _this4.paperData.multiList.length > 0) {
          _this4.cardItem = _this4.paperData.multiList[0];
        } else if (_this4.paperData.judgeList && _this4.paperData.judgeList.length > 0) {
          _this4.cardItem = _this4.paperData.judgeList[0];
        }
        var that = _this4;
        _this4.paperData.radioList.forEach(function (item) {
          that.allItem.push(item);
        });
        _this4.paperData.multiList.forEach(function (item) {
          that.allItem.push(item);
        });
        _this4.paperData.judgeList.forEach(function (item) {
          that.allItem.push(item);
        });

        // 当前选定
        _this4.fetchQuData(_this4.cardItem);
      });
    }
  }
};