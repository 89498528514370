//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pagingTree, fetchDetail, deleteData, saveData, sortData } from '@/api/sys/depart/depart';
import { exportExamNoPaper, exportLogPaging, fetchExamList, downloadZip } from '@/api/exam/exam';
import Pagination from '@/components/Pagination';
import { download } from '@/utils/request';
import DataTable from '@/components/DataTable';
import { batchImportZip, exportAreaReport } from '@/api/paper/paper';
import axios from 'axios';
export default {
  name: 'Depart',
  components: {
    Pagination: Pagination,
    DataTable: DataTable
  },
  data: function data() {
    return {
      defaultExpand: true,
      postForm: {},
      rules: {
        deptName: [{
          required: true,
          message: '部门名称不能为空!'
        }],
        deptType: [{
          required: true,
          message: '部门类型不能为空!'
        }]
      },
      dialogFormVisible: false,
      tableData: {
        total: 0,
        records: []
      },
      listLoading: true,
      listQuery: {
        current: 1,
        size: 1,
        params: {}
      },
      options: {
        // 可批量操作
        multi: false,
        // 批量操作列表
        multiActions: [{
          value: 'delete',
          label: '删除'
        }],
        // 列表请求URL
        listUrl: '/exam/api/export-Log/paging'
        // 添加数据路由
        // addRoute: 'AddRepo'
      },
      exams: [],
      deptTypes: [{
        id: 6,
        title: '市'
      }, {
        id: 5,
        title: '区'
      }, {
        id: 4,
        title: '大学'
      }, {
        id: 3,
        title: '高学'
      }, {
        id: 2,
        title: '中学'
      }, {
        id: 1,
        title: '小学'
      }],
      noPaperForm: {
        examIds: []
      },
      noPaperDialogVisible: false,
      examRules: {
        examIds: [{
          required: true,
          message: '答卷不能为空！',
          trigger: 'blur'
        }]
      },
      batchExportDialogVisible: false,
      exportLogs: [],
      batchExportForm: {
        listQuery: {
          current: 1,
          size: 1,
          params: {}
        }
      },
      batchExportFormRules: {
        examIds: [{
          required: true,
          message: '答卷不能为空！',
          trigger: 'blur'
        }]
      },
      areaReportDialogVisible: false,
      areaReportExportLogs: [],
      areaReportForm: {
        listQuery: {
          current: 1,
          size: 1,
          params: {}
        }
      },
      areaReportFormRules: {},
      areaReportOptions: {
        // 可批量操作
        multi: false,
        // 批量操作列表
        multiActions: [{
          value: 'delete',
          label: '删除'
        }],
        // 列表请求URL
        listUrl: '/exam/api/export-Log/paging'
        // 添加数据路由
        // addRoute: 'AddRepo'
      }
    };
  },
  watch: {
    // 测评查询变化
    listQuery: {
      handler: function handler() {
        this.getList();
      },
      deep: true
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    downloadFile: function downloadFile(row) {
      // const baseURL = process.env.VUE_APP_BASE_API
      var url = '/upload/file/' + row.filePath;
      axios({
        method: 'get',
        url: url,
        responseType: 'blob' // 重要：设置响应类型为blob
      }).then(function (response) {
        // 创建一个链接元素用于下载
        var url = window.URL.createObjectURL(new Blob([response.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.fileName); // 文件名设置为你希望下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }).catch(function (error) {
        console.error('下载文件时出错:', error);
      });
    },
    /**
       * 编辑窗口
       * @param parentId
       * @param currentId
       */
    formDialog: function formDialog(parentId, currentId) {
      var _this = this;
      // 清空表单
      this.postForm = {};

      // 覆盖上级分类
      this.postForm.refType = this.listQuery.refType;
      this.postForm.parentId = parentId;

      // 如果是修改
      if (currentId != null) {
        fetchDetail(currentId).then(function (response) {
          _this.postForm = response.data;
        });
      }
      this.dialogFormVisible = true;
    },
    noPaperDialog: function noPaperDialog(parentId, currentId, deptName) {
      // 清空表单
      this.noPaperForm = {};
      this.noPaperForm.deptId = currentId;
      this.noPaperForm.deptName = deptName;
      // this.noPaperForm.examIds = ['1769402744664817666', '1769403131757133825']
      this.getExamList(this.noPaperForm);
      this.noPaperDialogVisible = true;
    },
    batchExportDialog: function batchExportDialog(row) {
      // this.batchExportForm = {}
      // .parentId, scope., scope.row.deptName
      this.batchExportForm.listQuery.params.deptId = row.id;
      this.batchExportForm.listQuery.params.fileType = 0;
      // this.noPaperForm.examIds = ['1769402744664817666', '1769403131757133825']
      this.getExamList({
        deptId: row.id
      });
      this.batchExportDialogVisible = true;
      this.$refs.pagingTable.getList();
    },
    batchExportDialog1: function batchExportDialog1(row) {
      // 分析报告导出
      this.areaReportForm.listQuery.params.deptId = row.id;
      this.areaReportForm.listQuery.params.fileType = 1;
      // this.noPaperForm.examIds = ['1769402744664817666', '1769403131757133825']
      this.areaReportDialogVisible = true;
      this.$refs.pagingAreaReportTable.getList();
    },
    cleanBatchExportForm: function cleanBatchExportForm() {
      this.batchExportForm.examIds = null;
    },
    downloadFunc: function downloadFunc(data) {
      this.downloadFile(data);
    },
    getExamList: function getExamList(data) {
      var _this2 = this;
      fetchExamList(data).then(function (res) {
        console.log(res);
        if (res.code == 0) {
          _this2.exams = res.data;
          if (res.data) {
            // this.noPaperForm.examIds[0] = res.data[0]
          }
        }
      });
    },
    addExport: function addExport() {
      var _this3 = this;
      this.$refs.batchExportForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this3.batchExportForm.listQuery.params.examId = _this3.batchExportForm.examIds;
        batchImportZip(_this3.batchExportForm.listQuery.params).then(function (res) {
          if (res.code == 0) {
            _this3.$notify({
              title: '成功',
              message: '已添加导入,导出完成后点击下载即可！',
              type: 'success',
              duration: 2000
            });
            _this3.$refs.pagingTable.getList();
          }
        });
      });
    },
    addExport1: function addExport1() {
      var _this4 = this;
      this.$refs.areaReportForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        exportAreaReport(_this4.areaReportForm.listQuery.params).then(function (res) {
          if (res.code == 0) {
            _this4.$notify({
              title: '成功',
              message: '已添加导入,导出完成后点击下载即可！',
              type: 'success',
              duration: 2000
            });
            _this4.$refs.pagingAreaReportTable.getList();
          }
        });
      });
    },
    exportSearch1: function exportSearch1() {
      this.$refs.pagingAreaReportTable.getList();
    },
    exportSearch: function exportSearch() {
      console.log(this.batchExportForm.examIds);
      this.batchExportForm.listQuery.params.examId = this.batchExportForm.examIds;
      this.$refs.pagingTable.getList();
    },
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      pagingTree(this.listQuery).then(function (response) {
        var data = response.data;
        data.total = parseInt(data.total);
        _this5.tableData = data;
        //this.tableData = response.data
        _this5.listLoading = false;
      });
    },
    handleSort: function handleSort(id, sort) {
      var _this6 = this;
      sortData(id, sort).then(function () {
        _this6.$notify({
          title: '成功',
          message: '排序成功！',
          type: 'success',
          duration: 2000
        });
        _this6.getList();
      });
    },
    noPaperExport: function noPaperExport() {
      var _this7 = this;
      // download('/exam/api/qu/qu/export', data, '导出的数据.xlsx')
      this.$refs.noPaperForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        console.log(_this7.noPaperForm.examIds);
        var exam = _this7.exams.filter(function (res) {
          return res.id == _this7.noPaperForm.examIds;
        });
        _this7.noPaperForm.examName = exam[0].title;
        exportExamNoPaper(_this7.noPaperForm).then(function () {
          _this7.$notify({
            title: '成功',
            message: '导出成功！',
            type: 'success',
            duration: 2000
          });
          _this7.dialogFormVisible = false;
        });
      });
    },
    handleSave: function handleSave() {
      var _this8 = this;
      this.$refs.postForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        saveData(_this8.postForm).then(function () {
          _this8.$notify({
            title: '成功',
            message: '分类保存成功！',
            type: 'success',
            duration: 2000
          });
          _this8.dialogFormVisible = false;
          _this8.getList();
        });
      });
    },
    handleDelete: function handleDelete(id) {
      var _this9 = this;
      // 删除
      this.$confirm('确实要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var ids = [];
        ids.push(id);
        deleteData(ids).then(function () {
          _this9.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this9.getList();
        }).catch(function (err) {
          console.log(err);
        });
      });
    }
  }
};