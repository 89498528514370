"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.getInfos = getInfos;
exports.login = login;
exports.logout = logout;
exports.reg = reg;
var _request = require("@/utils/request");
function login(data) {
  return (0, _request.post)('/exam/api/sys/user/login', data);
}
function getInfo(token) {
  return (0, _request.post)('/exam/api/sys/user/info?token=' + token);
}
function getInfos(token) {
  return (0, _request.post)('/exam/api/sys/user/info', {
    'token': token
  });
}
function logout() {
  return (0, _request.post)('/exam/api/sys/user/logout', {});
}
function reg(data) {
  return (0, _request.post)('/exam/api/sys/user/reg', data);
}