var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h3", [_vm._v("组卷信息")]),
      _c("el-card", { staticStyle: { "margin-top": "20px" } }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v(" 添加量表 ")]
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "15px" },
                attrs: {
                  data: _vm.repoList,
                  border: false,
                  "empty-text": "请点击上面的`添加量表`进行设置",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "量表", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("repo-select", {
                            attrs: { multi: false, excludes: _vm.excludes },
                            on: {
                              change: function ($event) {
                                return _vm.repoChange($event, scope.row)
                              },
                            },
                            model: {
                              value: scope.row.repoId,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "repoId", $$v)
                              },
                              expression: "scope.row.repoId",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "单选数量", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(" " + _vm._s(scope.row.totalRadio) + " "),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "多选数量", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(" " + _vm._s(scope.row.totalMulti) + " "),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "判断题数量", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(" " + _vm._s(scope.row.totalJudge) + " "),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "删除", align: "center", width: "80px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-button", {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(scope.$index)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("h3", [_vm._v("测评配置")]),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "测评名称", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.postForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "title", $$v)
                      },
                      expression: "postForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测评描述", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.postForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content", $$v)
                      },
                      expression: "postForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测评时长(分钟)", prop: "totalTime" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.postForm.totalTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "totalTime", $$v)
                      },
                      expression: "postForm.totalTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否限时" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.postForm.timeLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "timeLimit", $$v)
                      },
                      expression: "postForm.timeLimit",
                    },
                  }),
                ],
                1
              ),
              _vm.postForm.timeLimit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "测评时间", prop: "totalTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.dateValues,
                          callback: function ($$v) {
                            _vm.dateValues = $$v
                          },
                          expression: "dateValues",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("权限配置")]),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-bottom": "20px" },
              model: {
                value: _vm.postForm.openType,
                callback: function ($$v) {
                  _vm.$set(_vm.postForm, "openType", $$v)
                },
                expression: "postForm.openType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1, border: "" } }, [
                _vm._v("完全公开"),
              ]),
              _c("el-radio", { attrs: { label: 2, border: "" } }, [
                _vm._v("部门开放"),
              ]),
            ],
            1
          ),
          _vm.postForm.openType === 1
            ? _c("el-alert", {
                attrs: {
                  title: "开放的，任何人都可以进行测评！",
                  type: "warning",
                },
              })
            : _vm._e(),
          _vm.postForm.openType === 2
            ? _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入关键字进行过滤" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.treeLoading,
                        expression: "treeLoading",
                      },
                    ],
                    ref: "tree",
                    attrs: {
                      data: _vm.treeData,
                      "default-checked-keys": _vm.postForm.departIds,
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode,
                      "empty-text": " ",
                      "default-expand-all": "",
                      "show-checkbox": "",
                      "node-key": "id",
                    },
                    on: { "check-change": _vm.handleCheckChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }