"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeState = changeState;
exports.deleteData = deleteData;
exports.fetchDetail = fetchDetail;
exports.fetchList = fetchList;
exports.getReportDetail = getReportDetail;
exports.saveData = saveData;
exports.uploadDocxAndDownPdf = uploadDocxAndDownPdf;
var _request = require("@/utils/request");
function fetchList(url, query) {
  return (0, _request.post)(url, query);
}
function fetchDetail(url, id) {
  return (0, _request.post)(url, {
    'id': id
  });
}
function saveData(url, data) {
  return (0, _request.post)(url, data);
}
function deleteData(url, ids) {
  return (0, _request.post)(url, {
    'ids': ids
  });
}
function changeState(url, ids, state) {
  return (0, _request.post)(url, {
    'ids': ids,
    'state': state
  });
}

//获取报告详情数据
function getReportDetail(url, paperId, userId) {
  return (0, _request.post)(url, {
    'paperId': paperId,
    'userId': userId
  });
}

//上传docx文件转pdf
function uploadDocxAndDownPdf(url, file, fileName) {
  return (0, _request.uploadDocxDownPdf)(url, file, fileName, null);
}