var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 4, sm: 4, lg: 4 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 测评总数 "),
                    ]),
                    _c("count-to", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.examPaperCount,
                        duration: 2600,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 4, sm: 4, lg: 4 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 题目总数 "),
                    ]),
                    _c("count-to", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.questionCount,
                        duration: 3000,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 4, sm: 4, lg: 4 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 答卷总数 "),
                    ]),
                    _c("count-to", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.doExamPaperCount,
                        duration: 3600,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 4, sm: 4, lg: 4 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 答题总数 "),
                    ]),
                    _c("count-to", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.doQuestionCount,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 4, sm: 4, lg: 4 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 学生总数 "),
                    ]),
                    _c("count-to", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.userCount,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }