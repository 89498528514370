"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _repo = require("@/api/qu/repo");
var _reporule = require("@/api/qu/reporule");
var _qu = require("@/api/qu/qu");
var _RepoSelect = _interopRequireDefault(require("@/components/RepoSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ruleRepo',
  components: {
    DataTable: _DataTable.default,
    RepoSelect: _RepoSelect.default
  },
  data: function data() {
    return {
      postForm: {
        repoId: '',
        quIds: [],
        nos: [],
        quObjs: []
      },
      quTypeDisabled: false,
      loading: false,
      quTypes: [{
        value: 1,
        label: '加'
      }, {
        value: 2,
        label: '减'
      }, {
        value: 3,
        label: '乘'
      }, {
        value: 4,
        label: '除'
      }],
      rules: {
        name: [{
          required: true,
          message: '名称不能为空！'
        }
        // ],
        // quIds: [
        //   { required: true, message: '名称不能为空！' }
        ]
      },
      listQuery: {
        multipleSelection: [],
        showDialog: false,
        current: 1,
        size: 100,
        params: {
          repoId: '',
          no: '',
          title: ''
        }
      },
      options: {
        // 可批量操作
        multi: true,
        // 批量操作列表
        multiActions: [{
          value: 'add-repo',
          label: '加入量表规则..'
        }],
        // 列表请求URL
        listUrl: '/exam/api/qu/qu/quSelect'
      }
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.fetchData(id);
    }
  },
  methods: {
    handleMultiAction: function handleMultiAction(v) {
      var _this = this;
      //  debugger
      // console.log(v.objs)
      //  console.log(v.ids)
      this.postForm.quIds = v.ids;
      this.postForm.quObjs = v.objs;
      //  console.log(this.postForm.quObjs.length)

      this.postForm.quObjs.forEach(function (row) {
        _this.postForm.nos.push(row.no);
      });
      this.listQuery.showDialog = false;
    },
    addQuestion: function addQuestion() {
      this.listQuery.params.repoId = this.postForm.repoId;
      this.listQuery.showDialog = true;
    },
    delQu: function delQu(i) {
      // debugger
      console.log(this.postForm.quObjs);
      this.postForm.quObjs.splice(i, 1);
      console.log(this.postForm.quObjs.length);
      this.$forceUpdate();
    },
    //
    fetchData: function fetchData(id) {
      var _this2 = this;
      var params = {
        id: id
      };
      (0, _reporule.ruleDetail)(params).then(function (response) {
        _this2.postForm = response.data;
      });
      // ruleDetail(params).then(response => {
      //   this.postForm = response.data
      // })
    },
    confirmQuestionSelect: function confirmQuestionSelect() {
      console.log(this.postForm.quObjs.length);
      this.listQuery.showDialog = false;
    },
    submitForm: function submitForm() {
      var _this3 = this;
      // console.log(JSON.stringify(this.postForm))
      this.$refs.postForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        (0, _reporule.saveRule)(_this3.postForm).then(function () {
          _this3.$notify({
            title: '成功',
            message: '量表保存成功！',
            type: 'success',
            duration: 2000
          });
          _this3.$router.push({
            name: 'ListRule'
          });
        });
      });
    },
    onCancel: function onCancel() {
      this.$router.push({
        name: 'ListRule'
      });
    }
  }
};