var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-card",
            [
              _c(
                "el-form-item",
                { attrs: { label: "归属量表", prop: "repoId" } },
                [
                  _c("repo-select", {
                    staticStyle: { width: "50%" },
                    model: {
                      value: _vm.postForm.repoId,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "repoId", $$v)
                      },
                      expression: "postForm.repoId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规则名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "规则名称" },
                    model: {
                      value: _vm.postForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "title", $$v)
                      },
                      expression: "postForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "乘除运算", prop: "ruleType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "50%" },
                      attrs: { disabled: _vm.quTypeDisabled },
                      model: {
                        value: _vm.postForm.ruleType,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "ruleType", $$v)
                        },
                        expression: "postForm.ruleType",
                      },
                    },
                    _vm._l(_vm.quTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "参数", prop: "number" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "参数", type: "number" },
                    model: {
                      value: _vm.postForm.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "number", $$v)
                      },
                      expression: "postForm.number",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规则描述", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "textarea", placeholder: "规则描述" },
                    model: {
                      value: _vm.postForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content", $$v)
                      },
                      expression: "postForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "题目集合", prop: "quIds" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "link-left",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.addQuestion()
                        },
                      },
                    },
                    [_vm._v(" 添加题目 ")]
                  ),
                  _vm.postForm.quObjs != null &&
                  _vm.postForm.quObjs.length !== 0
                    ? _c("el-card", { staticClass: "exampaper-item-box" }, [
                        _c(
                          "div",
                          { staticStyle: { "line-height": "20px" } },
                          _vm._l(_vm.postForm.quObjs, function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: index,
                                staticStyle: {
                                  "line-height": "20px",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticStyle: { "line-height": "20px" } },
                                  [
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c("span", { staticClass: "q-title" }, [
                                        _vm._v(_vm._s(item.no)),
                                      ]),
                                      _c("span", {
                                        staticClass: "q-item-content",
                                        domProps: {
                                          innerHTML: _vm._s(item.content),
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 1 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delQu(index)
                                              },
                                            },
                                          },
                                          [_vm._v("删除 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "info" }, on: { click: _vm.onCancel } },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.listQuery.showDialog, width: "70%" },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.listQuery, "showDialog", $event)
            },
          },
        },
        [
          _c("data-table", {
            ref: "pagingTable",
            attrs: { options: _vm.options, "list-query": _vm.listQuery },
            on: { "multi-actions": _vm.handleMultiAction },
            scopedSlots: _vm._u([
              {
                key: "filter-content",
                fn: function () {
                  return [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "搜索题目名称" },
                      model: {
                        value: _vm.listQuery.params.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "title", $$v)
                        },
                        expression: "listQuery.params.title",
                      },
                    }),
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "搜索题号" },
                      model: {
                        value: _vm.listQuery.params.no,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "no", $$v)
                        },
                        expression: "listQuery.params.no",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "data-columns",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: { label: "题号", prop: "no", align: "center" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "题目内容",
                        prop: "content",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属量表",
                        prop: "repoName",
                        align: "center",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }