"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _role = require("@/api/sys/role/role");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MeetRole',
  props: {
    value: Array,
    default: Array
  },
  data: function data() {
    return {
      // 下拉选项值
      list: [],
      values: []
    };
  },
  watch: {
    // 测评查询变化
    value: {
      handler: function handler() {
        this.values = this.value;
      },
      deep: true
    }
  },
  created: function created() {
    this.values = this.value;
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;
      (0, _role.fetchList)().then(function (response) {
        _this.list = response.data;
      });
    },
    handlerChange: function handlerChange(e) {
      this.$emit('change', e);
      this.$emit('input', e);
    }
  }
};