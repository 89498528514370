var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticStyle: { "margin-bottom": "20px" }, attrs: { span: 24 } },
            [
              _c("el-alert", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  title: "点击`开始测评`后将自动进入测评，请诚信测评！",
                  type: "error",
                },
              }),
              _c("el-card", { staticClass: "pre-exam" }, [
                _c("div", [
                  _c("strong", [_vm._v("测评名称：")]),
                  _vm._v(_vm._s(_vm.detailData.title)),
                ]),
                _c("div", [
                  _c("strong", [_vm._v("测评时长：")]),
                  _vm._v(_vm._s(_vm.detailData.totalTime) + "分钟"),
                ]),
                _c("div", [
                  _c("strong", [_vm._v("测评总分：")]),
                  _vm._v(_vm._s(_vm.detailData.totalScore) + "分"),
                ]),
                _c("div", [
                  _c("strong", [_vm._v("及格分数：")]),
                  _vm._v(_vm._s(_vm.detailData.qualifyScore) + "分"),
                ]),
                _c("div", [
                  _c("strong", [_vm._v("测评描述：")]),
                  _vm._v(_vm._s(_vm.detailData.content)),
                ]),
                _c("div", [
                  _c("strong", [_vm._v("开放类型：")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("examOpenType")(_vm.detailData.openType))
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    icon: "el-icon-caret-right",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" 开始测评 ")]
              ),
              _c("el-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" 返回 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }