"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchRuleAction = batchRuleAction;
exports.ruleDetail = ruleDetail;
exports.rulePaging = rulePaging;
exports.saveRule = saveRule;
var _request = require("@/utils/request");
/**
 * 量表详情
 * @param data
 */
function ruleDetail(data) {
  return (0, _request.post)('/exam/api/reporule/detail', data);
}

/**
 * 保存量表
 * @param data
 */
function saveRule(data) {
  return (0, _request.post)('/exam/api/reporule/save', data);
}

/**
 * 保存量表
 * @param data
 */
function rulePaging(data) {
  return (0, _request.post)('/exam/api/reporule/paging', data);
}

/**
 * 量表批量操作
 * @param data
 */
function batchRuleAction(data) {
  return (0, _request.post)('/exam/api/reporule/batch-action', data);
}