"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _MeetRole = _interopRequireDefault(require("@/components/MeetRole"));
var _user = require("@/api/sys/user/user");
var _DepartTreeSelect = _interopRequireDefault(require("@/components/DepartTreeSelect"));
var _depart = require("@/api/sys/depart/depart");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SysUserList',
  components: {
    DepartTreeSelect: _DepartTreeSelect.default,
    DataTable: _DataTable.default,
    MeetRole: _MeetRole.default
  },
  filters: {
    // 订单状态
    userState: function userState(value) {
      var map = {
        '0': '正常',
        '1': '禁用'
      };
      return map[value];
    }
  },
  data: function data() {
    return {
      importVisible: false,
      treeData: [],
      defaultProps: {
        value: 'id',
        label: 'deptName',
        children: 'children'
      },
      dialogVisible: false,
      listQuery: {
        current: 1,
        size: 10,
        params: {}
      },
      importData: {},
      formData: {
        avatar: ''
      },
      options: {
        // 列表请求URL
        listUrl: '/exam/api/sys/user/paging',
        // 启用禁用
        stateUrl: '/sys/user/state',
        deleteUrl: '/exam/api/sys/user/delete',
        // 批量操作列表
        multiActions: [{
          value: 'enable',
          label: '启用'
        }, {
          value: 'disable',
          label: '禁用'
        }, {
          value: 'delete',
          label: '删除'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _depart.fetchTree)({}).then(function (response) {
      _this.treeData = response.data;
    });
  },
  methods: {
    handleAdd: function handleAdd() {
      this.formData = {};
      this.dialogVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogVisible = true;
      this.formData = row;
      this.formData.roles = row.roleIds.split(',');
      this.formData.password = null;
      console.log(JSON.stringify(this.formData));
    },
    departSelected: function departSelected(data) {
      this.formData.departId = data.id;
    },
    handleSave: function handleSave() {
      var _this2 = this;
      (0, _user.saveData)(this.formData).then(function () {
        _this2.$message({
          type: 'success',
          message: '用户修改成功!'
        });
        _this2.dialogVisible = false;
        _this2.$refs.pagingTable.getList();
      });
    },
    // 批量操作监听
    handleMultiAction: function handleMultiAction(obj) {
      if (obj.opt === 'cancel') {
        this.handleCancelOrder(obj.ids);
      }
    },
    exportExcel: function exportExcel() {
      // 导出当前查询的数据
      (0, _user.exportExcel)(this.listQuery.params);
    },
    downloadTemplate: function downloadTemplate() {
      (0, _user.importTemplate)();
    },
    showImport: function showImport() {
      this.importVisible = true;
    },
    // 只是为了美化一下导入按钮
    chooseFile: function chooseFile() {
      this.$refs.upFile.dispatchEvent(new MouseEvent('click'));
    },
    doImport: function doImport(e) {
      var _this3 = this;
      var file = e.target.files[0];
      (0, _user.importExcel)(file).then(function (res) {
        if (res.code !== 0) {
          _this3.$alert(res.data.msg, '导入信息', {
            dangerouslyUseHTMLString: true
          });
        } else {
          _this3.$message({
            message: '数据导入成功！',
            type: 'success'
          });
          _this3.importVisible = false;
          _this3.$refs.pagingTable.getList();
        }
      });
    }
  }
};