"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkProcess = checkProcess;
exports.createPaper = createPaper;
exports.fillAnswer = fillAnswer;
exports.handExam = handExam;
exports.paperDetail = paperDetail;
exports.paperResult = paperResult;
exports.quDetail = quDetail;
exports.training = training;
var _request = require("@/utils/request");
/**
 * 创建测评
 * @param data
 */
function createPaper(data) {
  return (0, _request.post)('/exam/api/paper/paper/create-paper', data);
}

/**
 * 测评详情
 * @param data
 */
function paperDetail(data) {
  return (0, _request.post)('/exam/api/paper/paper/paper-detail', data);
}

/**
 * 题目详情
 * @param data
 */
function quDetail(data) {
  return (0, _request.post)('/exam/api/paper/paper/qu-detail', data);
}

/**
 * 填充答案
 * @param data
 */
function fillAnswer(data) {
  return (0, _request.post)('/exam/api/paper/paper/fill-answer', data);
}

/**
 * 交卷
 * @param data
 */
function handExam(data) {
  return (0, _request.post)('/exam/api/paper/paper/hand-exam', data);
}

/**
 * 测评详情
 * @param data
 */
function paperResult(data) {
  return (0, _request.post)('/exam/api/paper/paper/paper-result', data);
}

/**
 * 错题训练
 * @param data
 */
function training(data) {
  return (0, _request.post)('/exam/api/paper/paper/training', data);
}

/**
 * 检查是否有进行中的测评
 * @returns {*}
 */
function checkProcess() {
  return (0, _request.post)('/exam/api/paper/paper/check-process', {});
}