"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchImportZip = batchImportZip;
exports.exportAreaReport = exportAreaReport;
exports.listPaper = listPaper;
var _request = require("@/utils/request");
/**
 * 测评列表
 * @param data
 */
function listPaper(userId, examId) {
  return (0, _request.post)('/exam/api/paper/paper/paging', {
    current: 1,
    size: 5,
    params: {
      userId: userId,
      examId: examId
    }
  });
}
function batchImportZip(data) {
  return (0, _request.post)('/exam/api/paper/paper/batchImportZip', data);
}
function exportAreaReport(data) {
  return (0, _request.post)('/exam/api/export-Log/genTemplate', data);
}