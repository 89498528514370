"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTypeStateFilter = addTypeStateFilter;
exports.examOpenType = examOpenType;
exports.examStateFilter = examStateFilter;
Object.defineProperty(exports, "formatTime", {
  enumerable: true,
  get: function get() {
    return _utils.formatTime;
  }
});
exports.paperStateFilter = paperStateFilter;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
exports.quTypeFilter = quTypeFilter;
exports.sexFilter = sexFilter;
exports.stateFilter = stateFilter;
exports.uppercaseFirst = uppercaseFirst;
var _utils = require("@/utils");
var _depart = require("@/api/sys/depart/depart");
// import parseTime, formatTime and set to filter

/**
 * Upper case first char
 * @param {String} string
 */
function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 通用状态过滤器
 * @param value
 * @returns {*}
 */
function stateFilter(value) {
  var map = {
    '0': '正常',
    '1': '禁用'
  };
  return map[value];
}
function sexFilter(value) {
  var map = {
    '1': '男',
    '2': '女'
  };
  return map[value];
}
function quTypeFilter(value) {
  var map = {
    '1': '单选题',
    '2': '多选题',
    '3': '判断题'
  };
  return map[value];
}
function paperStateFilter(value) {
  var map = {
    '0': '测评中',
    '1': '待阅卷',
    '2': '已考完',
    '3': '!已弃考'
  };
  return map[value];
}
function examOpenType(value) {
  var map = {
    '1': '完全公开',
    '2': '指定部门'
  };
  return map[value];
}
function examStateFilter(value) {
  var map = {
    '0': '进行中',
    '1': '已禁用',
    '2': '待开始',
    '3': '已结束'
  };
  return map[value];
}
function addTypeStateFilter(value) {
  var map = {
    '1': '加',
    '2': '减',
    '3': '乘',
    '4': '除'
  };
  return map[value];
}