var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            [
              _c(
                "el-form-item",
                { attrs: { label: "归属量表", prop: "repoIds" } },
                [
                  _c("repo-select", {
                    attrs: { multi: true },
                    model: {
                      value: _vm.postForm.repoIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "repoIds", $$v)
                      },
                      expression: "postForm.repoIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "题目类型 ", prop: "quType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { disabled: _vm.quTypeDisabled },
                      on: { click: _vm.handleTypeChange },
                      model: {
                        value: _vm.postForm.quType,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "quType", $$v)
                        },
                        expression: "postForm.quType",
                      },
                    },
                    _vm._l(_vm.quTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "难度等级 ", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      model: {
                        value: _vm.postForm.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "level", $$v)
                        },
                        expression: "postForm.level",
                      },
                    },
                    _vm._l(_vm.levels, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "题目内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.postForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content", $$v)
                      },
                      expression: "postForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "试题图片" } },
                [
                  _c("file-upload", {
                    attrs: { accept: ".jpg,.jepg,.png" },
                    model: {
                      value: _vm.postForm.image,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "image", $$v)
                      },
                      expression: "postForm.image",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "整题解析", prop: "oriPrice" } },
                [
                  _c("el-input", {
                    attrs: { precision: 1, max: 999999, type: "textarea" },
                    model: {
                      value: _vm.postForm.analysis,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "analysis", $$v)
                      },
                      expression: "postForm.analysis",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.quType !== 4
            ? _c(
                "div",
                {
                  staticClass: "filter-container",
                  staticStyle: { "margin-top": "25px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "small",
                        plain: "",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(" 添加 ")]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.postForm.answerList, border: true },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "是否答案",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: scope.row.isRight,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isRight", $$v)
                                        },
                                        expression: "scope.row.isRight",
                                      },
                                    },
                                    [_vm._v("答案")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1650073960
                        ),
                      }),
                      _vm.itemImage
                        ? _c("el-table-column", {
                            attrs: {
                              label: "选项图片",
                              width: "120px",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("file-upload", {
                                        attrs: { accept: ".jpg,.jepg,.png" },
                                        model: {
                                          value: scope.row.image,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "image", $$v)
                                          },
                                          expression: "scope.row.image",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2051426284
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { label: "选项内容" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: scope.row.content,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "content", $$v)
                                      },
                                      expression: "scope.row.content",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          924406712
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "选项分值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: scope.row.itemScore,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "itemScore", $$v)
                                      },
                                      expression: "scope.row.itemScore",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4271943707
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "解析" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: scope.row.analysis,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "analysis", $$v)
                                      },
                                      expression: "scope.row.analysis",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3792987939
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "100px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-delete",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItem(scope.$index)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1518468532
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.onCancel } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }