"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _RepoSelect = _interopRequireDefault(require("@/components/RepoSelect"));
var _repo = require("@/api/qu/repo");
var _qu = require("@/api/qu/qu");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'QuList',
  components: {
    RepoSelect: _RepoSelect.default,
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      dialogTitle: '加入量表',
      dialogVisible: false,
      importVisible: false,
      dialogRepos: [],
      dialogQuIds: [],
      dialogFlag: false,
      listQuery: {
        current: 1,
        size: 10,
        params: {
          content: '',
          quType: '',
          repoIds: []
        }
      },
      quTypes: [{
        value: 1,
        label: '单选题'
      }, {
        value: 2,
        label: '多选题'
      }, {
        value: 3,
        label: '判断题'
      }],
      options: {
        // 可批量操作
        multi: true,
        // 批量操作列表
        multiActions: [{
          value: 'add-repo',
          label: '加入量表..'
        }, {
          value: 'remove-repo',
          label: '从..量表移除'
        }, {
          value: 'delete',
          label: '删除'
        }],
        // 列表请求URL
        listUrl: '/exam/api/qu/qu/paging',
        // 删除请求URL
        deleteUrl: '/exam/api/qu/qu/delete',
        // 添加数据路由
        addRoute: 'AddQu'
      }
    };
  },
  methods: {
    handleMultiAction: function handleMultiAction(obj) {
      if (obj.opt === 'add-repo') {
        this.dialogTitle = '加入量表';
        this.dialogFlag = false;
      }
      if (obj.opt === 'remove-repo') {
        this.dialogTitle = '从量表移除';
        this.dialogFlag = true;
      }
      this.dialogVisible = true;
      this.dialogQuIds = obj.ids;
    },
    handlerRepoAction: function handlerRepoAction() {
      var _this = this;
      var postForm = {
        repoIds: this.dialogRepos,
        quIds: this.dialogQuIds,
        remove: this.dialogFlag
      };
      (0, _repo.batchAction)(postForm).then(function () {
        _this.$notify({
          title: '成功',
          message: '批量操作成功！',
          type: 'success',
          duration: 2000
        });
        _this.dialogVisible = false;
        _this.$refs.pagingTable.getList();
      });
    },
    query: function query() {
      this.$refs.pagingTable.getList();
    },
    exportExcel: function exportExcel() {
      // 导出当前查询的数据
      (0, _qu.exportExcel)(this.listQuery.params);
    },
    downloadTemplate: function downloadTemplate() {
      (0, _qu.importTemplate)();
    },
    showImport: function showImport() {
      this.importVisible = true;
    },
    // 只是为了美化一下导入按钮
    chooseFile: function chooseFile() {
      this.$refs.upFile.dispatchEvent(new MouseEvent('click'));
    },
    doImport: function doImport(e) {
      var _this2 = this;
      var file = e.target.files[0];
      (0, _qu.importExcel)(file).then(function (res) {
        if (res.code !== 0) {
          _this2.$alert(res.data.msg, '导入信息', {
            dangerouslyUseHTMLString: true
          });
        } else {
          _this2.$message({
            message: '数据导入成功！',
            type: 'success'
          });
          _this2.importVisible = false;
          _this2.$refs.pagingTable.getList();
        }
      });
    }
  }
};