"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = require("@/utils/request");
var _default = exports.default = {
  index: function index() {
    return (0, _request.post)('/exam/api/admin/dashboard/index');
  }
};