"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['siteData']))
};