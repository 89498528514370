"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _exam = require("@/api/exam/exam");
var _exam2 = require("@/api/paper/exam");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      detailData: {},
      postForm: {
        examId: '',
        password: ''
      },
      rules: {
        password: [{
          required: true,
          message: '测评密码不能为空！'
        }]
      },
      loading: false
    };
  },
  created: function created() {
    this.postForm.examId = this.$route.params.examId;
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      (0, _exam.fetchDetail)(this.postForm.examId).then(function (response) {
        _this.detailData = response.data;
      });
    },
    handleCreate: function handleCreate() {
      var _this2 = this;
      var that = this;
      this.loading = true;
      (0, _exam2.createPaper)(this.postForm).then(function (response) {
        console.log(response);
        if (response.code === 0) {
          setTimeout(function () {
            this.loading = false;
            that.dialogVisible = false;
            that.$router.push({
              name: 'StartExam',
              params: {
                id: response.data.id
              }
            });
          }, 1000);
        }
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleBack: function handleBack() {
      this.$router.push({
        name: 'ExamOnline'
      });
    }
  }
};