"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _typeof2 = _interopRequireDefault(require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/typeof.js"));
var _exam = require("@/api/exam/exam");
var _depart = require("@/api/sys/depart/depart");
var _RepoSelect = _interopRequireDefault(require("@/components/RepoSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ExamDetail',
  components: {
    RepoSelect: _RepoSelect.default
  },
  data: function data() {
    return {
      treeData: [],
      defaultProps: {
        label: 'deptName'
      },
      filterText: '',
      treeLoading: false,
      dateValues: [],
      // 量表
      repoList: [],
      // 已选择的量表
      excludes: [],
      postForm: {
        // 总分数
        totalScore: 0,
        // 量表列表
        repoList: [],
        // 开放类型
        openType: 1,
        // 测评班级列表
        departIds: []
      },
      rules: {
        title: [{
          required: true,
          message: '测评名称不能为空！'
        }],
        content: [{
          required: true,
          message: '测评名称不能为空！'
        }],
        open: [{
          required: true,
          message: '测评权限不能为空！'
        }],
        totalTime: [{
          required: true,
          message: '测评时间不能为空！'
        }]
      }
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    },
    dateValues: {
      handler: function handler() {
        this.postForm.startTime = this.dateValues[0];
        this.postForm.endTime = this.dateValues[1];
      }
    },
    // 量表变换
    repoList: {
      handler: function handler(val) {
        var totalScore = 0;
        this.excludes = [];
        for (var i = 0; i < val.length; i++) {
          var item = val[i];
          if (item.radioCount > 0 && item.radioScore > 0) {
            totalScore += item.radioCount * item.radioScore;
          }
          if (item.multiCount > 0 && item.multiScore > 0) {
            totalScore += item.multiCount * item.multiScore;
          }
          if (item.judgeCount > 0 && item.judgeScore > 0) {
            totalScore += item.judgeCount * item.judgeScore;
          }
          this.excludes.push(item.id);
        }

        // 赋值
        this.postForm.totalScore = totalScore;
        this.postForm.repoList = val;
        this.$forceUpdate();
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    var id = this.$route.params.id;
    if ((0, _typeof2.default)(id) !== undefined) {
      this.fetchData(id);
    }
    (0, _depart.fetchTree)({}).then(function (response) {
      _this.treeData = response.data;
    });
  },
  methods: {
    handleSave: function handleSave() {
      var _this2 = this;
      this.$refs.postForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        // if (this.postForm.totalScore === 0) {
        //   this.$notify({
        //     title: '提示信息',
        //     message: '测评规则设置不正确，请确认！',
        //     type: 'warning',
        //     duration: 2000
        //   })
        //
        //   return
        // }

        for (var i = 0; i < _this2.postForm.repoList.length; i++) {
          var repo = _this2.postForm.repoList[i];
          if (!repo.repoId) {
            _this2.$notify({
              title: '提示信息',
              message: '测评量表选择不正确！',
              type: 'warning',
              duration: 2000
            });
            return;
          }

          // if ((repo.radioCount > 0 && repo.radioScore === 0) || (repo.radioCount === 0 && repo.radioScore > 0)) {
          //   this.$notify({
          //     title: '提示信息',
          //     message: '量表第：[' + (i + 1) + ']项存在无效的单选题配置！',
          //     type: 'warning',
          //     duration: 2000
          //   })
          //
          //   return
          // }

          // if ((repo.multiCount > 0 && repo.multiScore === 0) || (repo.multiCount === 0 && repo.multiScore > 0)) {
          //   this.$notify({
          //     title: '提示信息',
          //     message: '量表第：[' + (i + 1) + ']项存在无效的多选题配置！',
          //     type: 'warning',
          //     duration: 2000
          //   })
          //
          //   return
          // }

          // if ((repo.judgeCount > 0 && repo.judgeScore === 0) || (repo.judgeCount === 0 && repo.judgeScore > 0)) {
          //   this.$notify({
          //     title: '提示信息',
          //     message: '量表第：[' + (i + 1) + ']项存在无效的判断题配置！',
          //     type: 'warning',
          //     duration: 2000
          //   })
          //   return
          // }
        }
        _this2.$confirm('确实要提交保存吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.submitForm();
        });
      });
    },
    handleCheckChange: function handleCheckChange() {
      var that = this;
      // 置空
      this.postForm.departIds = [];
      var nodes = this.$refs.tree.getCheckedNodes();
      nodes.forEach(function (item) {
        that.postForm.departIds.push(item.id);
      });
    },
    // 添加子项
    handleAdd: function handleAdd() {
      this.repoList.push({
        id: '',
        rowId: new Date().getTime(),
        radioCount: 0,
        radioScore: 0,
        multiCount: 0,
        multiScore: 0,
        judgeCount: 0,
        judgeScore: 0,
        saqCount: 0,
        saqScore: 0
      });
    },
    removeItem: function removeItem(index) {
      this.repoList.splice(index, 1);
    },
    fetchData: function fetchData(id) {
      var _this3 = this;
      (0, _exam.fetchDetail)(id).then(function (response) {
        _this3.postForm = response.data;
        if (_this3.postForm.startTime && _this3.postForm.endTime) {
          _this3.dateValues[0] = _this3.postForm.startTime;
          _this3.dateValues[1] = _this3.postForm.endTime;
        }
        _this3.repoList = _this3.postForm.repoList;
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;
      // 校验和处理数据
      this.postForm.repoList = this.repoList;
      (0, _exam.saveData)(this.postForm).then(function () {
        _this4.$notify({
          title: '成功',
          message: '测评保存成功！',
          type: 'success',
          duration: 2000
        });
        _this4.$router.push({
          name: 'ListExam'
        });
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.deptName.indexOf(value) !== -1;
    },
    repoChange: function repoChange(e, row) {
      // 赋值ID
      row.id = e.id;
      if (e != null) {
        row.totalRadio = e.radioCount;
        row.totalMulti = e.multiCount;
        row.totalJudge = e.judgeCount;
      } else {
        row.totalRadio = 0;
        row.totalMulti = 0;
        row.totalJudge = 0;
      }
    }
  }
};