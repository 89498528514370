"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportExcel = exportExcel;
exports.fetchDetail = fetchDetail;
exports.importExcel = importExcel;
exports.importTemplate = importTemplate;
exports.quSelect = quSelect;
exports.saveData = saveData;
var _request = require("@/utils/request");
/**
 * 量表详情
 * @param data
 */
function quSelect(data) {
  return (0, _request.post)('/exam/api/qu/qu/quSelect', data);
}
/**
 * 量表详情
 * @param data
 */
function fetchDetail(id) {
  return (0, _request.post)('/exam/api/qu/qu/detail', {
    id: id
  });
}

/**
 * 保存量表
 * @param data
 */
function saveData(data) {
  return (0, _request.post)('/exam/api/qu/qu/save', data);
}

/**
 * 导出
 * @param data
 */
function exportExcel(data) {
  return (0, _request.download)('/exam/api/qu/qu/export', data, '导出的数据.xlsx');
}

/**
 * 导入模板
 * @param data
 */
function importTemplate() {
  return (0, _request.download)('/exam/api/qu/qu/import/template', {}, 'qu-import-template.xlsx');
}

/**
 * 导出
 * @param data
 */
function importExcel(file) {
  return (0, _request.upload)('/exam/api/qu/qu/import', file);
}