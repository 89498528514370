"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDetail = fetchDetail;
exports.saveData = saveData;
var _request = require("@/utils/request");
// 获得用户协议详情，固定ID为0
function fetchDetail() {
  return (0, _request.post)('/exam/api/sys/config/detail', {
    id: '1'
  });
}
function saveData(data) {
  return (0, _request.post)('/exam/api/sys/config/save', data);
}