var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "搜索题目内容" },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { float: "right" },
                attrs: { type: "primary", icon: "el-icon-magic-stick" },
                on: { click: _vm.startTrain },
              },
              [_vm._v(" 错题训练 ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "题目内容", "show-overflow-tooltip": "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "ViewQu",
                              params: { id: scope.row.quId },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(scope.row.title) + " ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "错误次数",
                prop: "wrongCount",
                align: "center",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "更新时间",
                align: "center",
                prop: "updateTime",
                width: "180px",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }