"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
var _config = require("@/api/sys/config/config");
var showSettings = _settings.default.showSettings,
  tagsView = _settings.default.tagsView,
  fixedHeader = _settings.default.fixedHeader,
  sidebarLogo = _settings.default.sidebarLogo;
var state = {
  theme: _elementVariables.default.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  siteData: {}
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  SET_SITE_DATA: function SET_SITE_DATA(state, siteData) {
    state.siteData = siteData;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  },
  // 获取网站配置信息
  getSite: function getSite(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _config.fetchDetail)({}).then(function (response) {
        var data = response.data;
        commit('SET_SITE_DATA', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};