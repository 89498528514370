"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    PanThumb: _PanThumb.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: ''
        };
      }
    }
  }
};