"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _common = require("@/api/common");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'PagingTable',
  components: {
    Pagination: _Pagination.default
  },
  // 组件入参
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {
          // 批量操作
          multiActions: [],
          // 列表请求URL
          listUrl: '/exam/api',
          // 删除请求URL
          deleteUrl: '',
          // 启用禁用
          stateUrl: '',
          // 可批量操作
          multi: false
        };
      }
    },
    // 列表查询参数
    listQuery: {
      type: Object,
      default: function _default() {
        return {
          current: 1,
          size: 50,
          params: {},
          t: 0
        };
      }
    }
  },
  data: function data() {
    return {
      // 接口数据返回
      dataList: {
        total: 0
      },
      // 数据加载标识
      listLoading: true,
      // 选定和批量操作
      selectedIds: [],
      selectedObjs: [],
      // 显示已中多少项
      selectedLabel: '',
      // 显示批量操作
      multiShow: false,
      // 批量操作的标识
      multiNow: ''
    };
  },
  watch: {
    // 测评查询变化
    listQuery: {
      handler: function handler() {
        this.getList();
      },
      deep: true
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 添加数据跳转
     */
    handleAdd: function handleAdd() {
      if (this.options.addRoute) {
        this.$router.push({
          name: this.options.addRoute,
          params: {}
        });
        return;
      }
      console.log('未设置添加数据跳转路由！');
    },
    /**
     * 查询数据列表
     */
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.t = new Date().getTime();
      (0, _common.fetchList)(this.options.listUrl, this.listQuery).then(function (response) {
        var data = response.data;
        data.total = parseInt(data.total);
        _this.dataList = data;
        //this.dataList = response.data
        _this.listLoading = false;
      });
    },
    /**
     * 搜索
     */
    handleFilter: function handleFilter() {
      // 重新搜索
      this.getList();
    },
    /**
     * 批量操作回调
     */
    handleOption: function handleOption(v) {
      this.multiNow = '';

      // 内部消化的操作
      if (v === 'delete') {
        this.handleDelete();
        return;
      }
      if (v === 'enable') {
        this.handleState(0);
        return;
      }
      if (v === 'disable') {
        this.handleState(1);
        return;
      }

      // 向外回调的操作
      this.$emit('multi-actions', {
        opt: v,
        ids: this.selectedIds,
        objs: this.selectedObjs
      });
    },
    /**
     * 修改状态，启用禁用
     */
    handleState: function handleState(state) {
      var _this2 = this;
      // 修改状态
      (0, _common.changeState)(this.options.stateUrl, this.selectedIds, state).then(function (response) {
        if (response.code === 0) {
          _this2.$message({
            type: 'success',
            message: '状态修改成功!'
          });

          // 重新搜索
          _this2.getList();
        }
      });
    },
    /**
     * 删除数据
     */
    handleDelete: function handleDelete() {
      var _this3 = this;
      if (this.selectedIds.length === 0) {
        this.$message({
          message: '请至少选择一条数据！',
          type: 'warning'
        });
        return;
      }

      // 删除
      this.$confirm('确实要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _common.deleteData)(_this3.options.deleteUrl, _this3.selectedIds).then(function () {
          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this3.getList();
        });
      });
    },
    /**
     * 列表多选操作
     * @param val
     */
    handleSelection: function handleSelection(val) {
      var ids = [];
      val.forEach(function (row) {
        ids.push(row.id);
      });
      this.selectedObjs = val;
      this.selectedIds = ids;
      this.multiShow = ids.length > 0;
      this.selectedLabel = '已选' + ids.length + '项';
      this.$emit('select-changed', {
        ids: this.selectedIds,
        objs: this.selectedObjs
      });
    }
  }
};