"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ListRule',
  components: {
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: ''
        }
      },
      options: {
        // 可批量操作
        multi: true,
        // 批量操作列表
        multiActions: [{
          value: 'delete',
          label: '删除'
        }],
        // 列表请求URL
        listUrl: '/exam/api/reporule/paging',
        // 删除请求URL
        deleteUrl: '/exam/api/reporule/delete',
        // 启用禁用
        stateUrl: '/qu/repo/state',
        // 添加数据路由
        addRoute: 'AddRule'
      }
    };
  },
  methods: {
    handleUpdateExam: function handleUpdateExam(examId) {
      this.$router.push({
        name: 'setRuleRopo',
        params: {
          id: examId
        }
      });
    }
  }
};