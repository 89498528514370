"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _repo = require("@/api/qu/repo");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'QuRepoDetail',
  data: function data() {
    return {
      postForm: {},
      loading: false,
      rules: {
        title: [{
          required: true,
          message: '量表名称不能为空！'
        }]
      }
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.fetchData(id);
    }
  },
  methods: {
    // 添加子项
    handleAdd: function handleAdd() {
      this.postForm.answerList.push({
        isRight: false,
        content: '',
        analysis: ''
      });
    },
    fetchData: function fetchData(id) {
      var _this = this;
      var params = {
        id: id
      };
      (0, _repo.fetchDetail)(params).then(function (response) {
        _this.postForm = response.data;
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;
      console.log(JSON.stringify(this.postForm));
      this.$refs.postForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        (0, _repo.saveData)(_this2.postForm).then(function () {
          _this2.$notify({
            title: '成功',
            message: '量表保存成功！',
            type: 'success',
            duration: 2000
          });
          _this2.$router.push({
            name: 'ListRepo'
          });
        });
      });
    },
    onCancel: function onCancel() {
      this.$router.push({
        name: 'ListRepo'
      });
    }
  }
};