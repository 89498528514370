"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'QuList',
  components: {
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: '',
          examId: ''
        }
      },
      options: {
        // 可批量操作
        multi: true,
        // 批量操作列表
        multiActions: [{
          value: 'delete',
          label: '删除'
        }],
        // 列表请求URL
        listUrl: '/exam/api/user/wrong-book/paging',
        // 删除请求URL
        deleteUrl: '/exam/api/user/wrong-book/delete'
      }
    };
  },
  created: function created() {
    var id = this.$route.params.examId;
    if (typeof id !== 'undefined') {
      this.listQuery.params.examId = id;
      this.fetchData(id);
    }
  },
  methods: {
    startTrain: function startTrain() {
      this.$router.push({
        name: 'BookTraining',
        params: {
          examId: this.listQuery.params.examId
        }
      });
    }
  }
};