"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      postForm: {
        mobile: '',
        password: ''
      },
      loginRules: {
        password: [{
          required: true,
          trigger: 'blur',
          message: '登录密码不能为空！'
        }],
        userName: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空！'
        }],
        realName: [{
          required: true,
          trigger: 'blur',
          message: '姓名不能为空！'
        }],
        captchaValue: [{
          required: true,
          trigger: 'blur',
          message: '验证码不能为空'
        }]
      },
      loading: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['siteData'])),
  methods: {
    handleReg: function handleReg() {
      var _this = this;
      this.$refs.postForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _this.$store.dispatch('user/reg', _this.postForm).then(function () {
            _this.$router.push({
              path: _this.redirect || '/admin/dashboard'
            });
            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          });
        }
      });
    }
  }
};