var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.paperData.title)),
      ]),
      _c("p", { staticClass: "text-center", staticStyle: { color: "#666" } }, [
        _vm._v(_vm._s(_vm.paperData.createTime)),
      ]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "50px" }, attrs: { gutter: 24 } },
        [
          _c("el-col", { staticClass: "text-center", attrs: { span: 8 } }, [
            _vm._v(" 考生姓名：" + _vm._s(_vm.paperData.userId_dictText) + " "),
          ]),
          _c("el-col", { staticClass: "text-center", attrs: { span: 8 } }, [
            _vm._v(" 测评用时：" + _vm._s(_vm.paperData.userTime) + "分钟 "),
          ]),
          _c("el-col", { staticClass: "text-center", attrs: { span: 8 } }, [
            _vm._v(" 测评得分：" + _vm._s(_vm.paperData.userScore) + " "),
          ]),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        _vm._l(_vm.paperData.quList, function (item) {
          return _c("div", { key: item.id, staticClass: "qu-content" }, [
            _c("p", [
              _vm._v(
                _vm._s(item.sort + 1) +
                  "." +
                  _vm._s(item.content) +
                  "（得分：" +
                  _vm._s(item.score) +
                  "）"
              ),
            ]),
            item.image != null && item.image != ""
              ? _c(
                  "p",
                  [
                    _c("el-image", {
                      staticStyle: { "max-width": "100%" },
                      attrs: { src: item.image },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            item.quType === 1 || item.quType === 3
              ? _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.radioValues[item.id],
                          callback: function ($$v) {
                            _vm.$set(_vm.radioValues, item.id, $$v)
                          },
                          expression: "radioValues[item.id]",
                        },
                      },
                      _vm._l(item.answerList, function (an) {
                        return _c("el-radio", { attrs: { label: an.id } }, [
                          _vm._v(
                            " " +
                              _vm._s(an.abc) +
                              "." +
                              _vm._s(an.content) +
                              " "
                          ),
                          an.image != null && an.image != ""
                            ? _c(
                                "div",
                                { staticStyle: { clear: "both" } },
                                [
                                  _c("el-image", {
                                    staticStyle: { "max-width": "100%" },
                                    attrs: { src: an.image },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        !item.answered
                          ? _c(
                              "el-col",
                              {
                                staticStyle: { color: "#ff0000" },
                                attrs: { span: 12 },
                              },
                              [_vm._v(" 答题结果：未答 ")]
                            )
                          : _vm._e(),
                        item.answered && !item.isRight
                          ? _c(
                              "el-col",
                              {
                                staticStyle: { color: "#ff0000" },
                                attrs: { span: 12 },
                              },
                              [
                                _vm._v(
                                  " 答题结果：" +
                                    _vm._s(_vm.myRadio[item.id]) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.answered && item.isRight
                          ? _c(
                              "el-col",
                              {
                                staticStyle: { color: "#24da70" },
                                attrs: { span: 12 },
                              },
                              [
                                _vm._v(
                                  " 答题结果：" +
                                    _vm._s(_vm.myRadio[item.id]) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            item.quType === 4
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(" 我的回答：" + _vm._s(item.answer) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            item.quType === 2
              ? _c(
                  "div",
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.multiValues[item.id],
                          callback: function ($$v) {
                            _vm.$set(_vm.multiValues, item.id, $$v)
                          },
                          expression: "multiValues[item.id]",
                        },
                      },
                      _vm._l(item.answerList, function (an) {
                        return _c(
                          "el-checkbox",
                          { key: an.id, attrs: { label: an.id } },
                          [
                            _vm._v(
                              _vm._s(an.abc) + "." + _vm._s(an.content) + " "
                            ),
                            an.image != null && an.image != ""
                              ? _c(
                                  "div",
                                  { staticStyle: { clear: "both" } },
                                  [
                                    _c("el-image", {
                                      staticStyle: { "max-width": "100%" },
                                      attrs: { src: an.image },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { color: "#24da70" },
                            attrs: { span: 12 },
                          },
                          [
                            _vm._v(
                              " 正确答案：" +
                                _vm._s(_vm.multiRights[item.id].join(",")) +
                                " "
                            ),
                          ]
                        ),
                        !item.answered
                          ? _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  color: "#ff0000",
                                },
                                attrs: { span: 12 },
                              },
                              [_vm._v(" 答题结果：未答 ")]
                            )
                          : _vm._e(),
                        item.answered && !item.isRight
                          ? _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  color: "#ff0000",
                                },
                                attrs: { span: 12 },
                              },
                              [
                                _vm._v(
                                  " 答题结果：" +
                                    _vm._s(_vm.myMulti[item.id].join(",")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.answered && item.isRight
                          ? _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  color: "#24da70",
                                },
                                attrs: { span: 12 },
                              },
                              [
                                _vm._v(
                                  " 答题结果：" +
                                    _vm._s(_vm.myMulti[item.id].join(",")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }