"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _local = _interopRequireDefault(require("./local"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FileUpload',
  components: {
    FileUploadLocal: _local.default
  },
  props: {
    value: String,
    accept: {
      type: String,
      default: '*'
    },
    tips: String,
    listType: {
      type: String,
      default: 'picture'
    }
  },
  data: function data() {
    return {
      fileUrl: ''
    };
  },
  watch: {
    // 测评查询变化
    value: {
      handler: function handler() {
        this.fillValue();
      }
    },
    // 测评查询变化
    fileUrl: {
      handler: function handler() {
        this.$emit('input', this.fileUrl);
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {
    this.fillValue();
  },
  methods: {
    fillValue: function fillValue() {
      this.fileUrl = this.value;
    }
  }
};