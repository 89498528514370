var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("file-upload-local", {
        attrs: {
          accept: _vm.accept,
          tips: _vm.tips,
          "list-type": _vm.listType,
        },
        model: {
          value: _vm.fileUrl,
          callback: function ($$v) {
            _vm.fileUrl = $$v
          },
          expression: "fileUrl",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }