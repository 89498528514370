"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nextQu = nextQu;
var _request = require("@/utils/request");
/**
 * 量表详情
 * @param data
 */
function nextQu(examId, quId) {
  return (0, _request.post)('/exam/api/user/wrong-book/next', {
    examId: examId,
    quId: quId
  });
}