var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "remote-method": _vm.fetchList,
        multiple: "",
        filterable: "",
        remote: "",
        "reserve-keyword": "",
        clearable: "",
        "automatic-dropdown": "",
        placeholder: "请选择角色",
      },
      on: { change: _vm.handlerChange },
      model: {
        value: _vm.values,
        callback: function ($$v) {
          _vm.values = $$v
        },
        expression: "values",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.title, value: item.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }