"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _paper = _interopRequireDefault(require("./paper"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MyExamList',
  components: {
    MyPaperList: _paper.default,
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      examId: '',
      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: ''
        }
      },
      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/exam/api/user/exam/my-paging'
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['userId'])),
  methods: {
    // 开始测评
    handleExamDetail: function handleExamDetail(examId) {
      this.examId = examId;
      this.dialogVisible = true;
    },
    handlerExamBook: function handlerExamBook(examId) {
      this.$router.push({
        name: 'BookList',
        params: {
          examId: examId
        }
      });
    }
  }
};