"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _dashboard = _interopRequireDefault(require("@/api/dashboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      examPaperCount: 0,
      questionCount: 0,
      doExamPaperCount: 0,
      doQuestionCount: 0,
      userCount: 0,
      echartsQuestion: null,
      loading: false
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    var _this = this;
    this.loading = true;
    _dashboard.default.index().then(function (re) {
      var response = re.data;
      _this.examPaperCount = response.examPaperCount;
      _this.questionCount = response.questionCount;
      _this.doExamPaperCount = response.doExamPaperCount;
      _this.doQuestionCount = response.doQuestionCount;
      _this.userCount = response.userCount;
      _this2.loading = false;
    });
  },
  methods: {}
};