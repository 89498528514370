"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadZip = downloadZip;
exports.exportExamNoPaper = exportExamNoPaper;
exports.exportLogPaging = exportLogPaging;
exports.exportWord = exportWord;
exports.fetchDetail = fetchDetail;
exports.fetchExamList = fetchExamList;
exports.fetchList = fetchList;
exports.saveData = saveData;
var _request = require("@/utils/request");
/**
 * 量表详情
 * @param data
 */
function fetchDetail(id) {
  return (0, _request.post)('/exam/api/exam/exam/detail', {
    id: id
  });
}

/**
 * 保存量表
 * @param data
 */
function saveData(data) {
  return (0, _request.post)('/exam/api/exam/exam/save', data);
}

/**
 * 量表详情
 * @param data
 */
function fetchList() {
  return (0, _request.post)('/exam/api/exam/exam/paging', {
    current: 1,
    size: 100
  });
}
function fetchExamList(data) {
  return (0, _request.post)('/exam/api/exam/exam/exam-by-dept?deptId=' + data.deptId);
}
function downloadZip(data) {
  return (0, _request.download)('/upload/file/' + data.filePath, data.fileName);
}
/**
 * 未参考人员导出
 * @param data
 */
function exportExamNoPaper(data) {
  return (0, _request.download)('/exam/api/exam/exam/exam-no-paper', data, data.deptName + data.examName + '未参测人员数据.xlsx');
}

/**
 * 量表详情
 * @param data
 */
function exportWord() {
  return (0, _request.exportToWord)('/exam/api/user/exam/exportWord', {}, 'exportWord-import-template');
}

/**
 * 量表详情
 * @param data
 */
function exportLogPaging(data) {
  return (0, _request.post)('/exam/api/export-Log/paging', data);
}