"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FileUploadLocal',
  props: {
    value: String,
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      server: "".concat(process.env.VUE_APP_BASE_API, "/exam/api/file/upload"),
      fileList: [],
      fileUrl: '',
      header: {}
    };
  },
  watch: {
    // 测评查询变化
    value: {
      handler: function handler() {
        this.fillValue();
      }
    }
  },
  created: function created() {
    this.fillValue();
    this.header = {
      token: (0, _auth.getToken)()
    };
  },
  methods: {
    fillValue: function fillValue() {
      this.fileList = [];
      this.fileUrl = this.value;
      if (this.fileUrl) {
        this.fileList = [{
          name: this.fileUrl,
          url: this.fileUrl
        }];
      }
    },
    // 文件超出个数限制时的钩子
    handleExceed: function handleExceed() {
      this.$message.warning("\u6BCF\u6B21\u53EA\u80FD\u4E0A\u4F20 ".concat(this.limit, " \u4E2A\u6587\u4EF6"));
    },
    // 删除文件之前的钩子
    beforeRemove: function beforeRemove() {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664\u6587\u4EF6\u5417\uFF1F");
    },
    // 文件列表移除文件时的钩子
    handleRemove: function handleRemove() {
      this.$emit('input', '');
      this.fileList = [];
    },
    // 文件上传成功时的钩子
    handleSuccess: function handleSuccess(response) {
      if (response.code === 1) {
        this.$message({
          type: 'error',
          message: response.msg
        });
        this.fileList = [];
        return;
      }
      this.$emit('input', response.data.url);
    }
  }
};