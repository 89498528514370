"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchAction = batchAction;
exports.fetchDetail = fetchDetail;
exports.fetchPaging = fetchPaging;
exports.saveData = saveData;
var _request = require("@/utils/request");
/**
 * 量表详情
 * @param data
 */
function fetchDetail(data) {
  return (0, _request.post)('/exam/api/repo/detail', data);
}

/**
 * 保存量表
 * @param data
 */
function saveData(data) {
  return (0, _request.post)('/exam/api/repo/save', data);
}

/**
 * 保存量表
 * @param data
 */
function fetchPaging(data) {
  return (0, _request.post)('/exam/api/repo/paging', data);
}

/**
 * 量表批量操作
 * @param data
 */
function batchAction(data) {
  return (0, _request.post)('/exam/api/repo/batch-action', data);
}