var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            [
              _c(
                "el-form-item",
                { attrs: { label: "量表名称", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.postForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "title", $$v)
                      },
                      expression: "postForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "量表备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.postForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "remark", $$v)
                      },
                      expression: "postForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.onCancel } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }