"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _repo = require("@/api/qu/repo");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'RepoSelect',
  props: {
    multi: {
      type: Boolean,
      default: false
    },
    value: String | Array,
    excludes: Array
  },
  data: function data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    };
  },
  watch: {
    // 测评查询变化
    value: {
      handler: function handler() {
        this.currentValue = this.value;
      }
    }
  },
  created: function created() {
    this.currentValue = this.value;
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData(q) {
      var _this = this;
      (0, _repo.fetchPaging)({
        current: 1,
        size: 1000,
        params: {
          title: q,
          excludes: this.excludes
        }
      }).then(function (res) {
        _this.dataList = res.data.records;
      });
    },
    handlerChange: function handlerChange(e) {
      var obj = this.dataList.find(function (item) {
        return item.id === e;
      });
      this.$emit('change', obj);
      this.$emit('input', e);
    }
  }
};