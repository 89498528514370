"use strict";

var _interopRequireWildcard = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("C:\\tool\\learn\\other\\exam-vue\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\tool\\learn\\other\\exam-vue\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\tool\\learn\\other\\exam-vue\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\tool\\learn\\other\\exam-vue\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
// a modern alternative to CSS resets

// icon
// permission control
// error log

// Element UI
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});

// 注册全局过滤器
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;

// 环境标识
_vue.default.prototype.$demo = process.env.NODE_ENV === 'demo';
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});