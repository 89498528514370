"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportExcel = exportExcel;
exports.importExcel = importExcel;
exports.importTemplate = importTemplate;
exports.saveData = saveData;
exports.updateData = updateData;
exports.userReg = userReg;
var _request = require("@/utils/request");
function updateData(data) {
  return (0, _request.post)('/exam/api/sys/user/update', data);
}
function saveData(data) {
  return (0, _request.post)('/exam/api/sys/user/save', data);
}
function userReg(data) {
  return (0, _request.post)('/exam/api/sys/user/reg', data);
}

/**
 * 导出
 * @param data
 */
function exportExcel(data) {
  return (0, _request.download)('/exam/api/sys/user/export', data, '导出的数据.xlsx');
}

/**
 * 导入模板
 * @param data
 */
function importTemplate() {
  return (0, _request.download)('/exam/api/sys/user/import/template', {}, 'user-import-template.xlsx');
}

/**
 * 导出
 * @param data
 */
function importExcel(file) {
  return (0, _request.upload)('/exam/api/sys/user/import', file);
}