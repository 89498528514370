"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _DepartTreeSelect = _interopRequireDefault(require("@/components/DepartTreeSelect"));
var _depart = require("@/api/sys/depart/depart");
var _paper = require("@/api/paper/paper");
var _ExamSelect = _interopRequireDefault(require("@/components/ExamSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ExamSelect: _ExamSelect.default,
    DepartTreeSelect: _DepartTreeSelect.default,
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      captureList: [],
      paperStates: [{
        value: 0,
        label: '测评中'
      }, {
        value: 1,
        label: '待阅卷'
      }, {
        value: 2,
        label: '已考完'
      }, {
        value: 3,
        label: '!已弃考'
      }],
      treeData: [],
      defaultProps: {
        value: 'id',
        label: 'deptName',
        children: 'children'
      },
      listQuery: {
        current: 1,
        size: 10,
        params: {
          examId: ''
        }
      },
      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/exam/api/paper/paper/paging'
      }
    };
  },
  created: function created() {
    var _this = this;
    var examId = this.$route.params.examId;
    if (typeof examId !== 'undefined') {
      this.listQuery.params.examId = examId;
    }
    (0, _depart.fetchTree)({}).then(function (response) {
      _this.treeData = response.data;
    });
  },
  methods: {
    handleCapture: function handleCapture(paperId) {
      var _this2 = this;
      this.dialogVisible = true;
      (0, _paper.listCaptures)(paperId).then(function (res) {
        _this2.captureList = res.data;
      });
    }
  }
};