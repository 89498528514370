var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "搜索量表名称" },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "量表ID", prop: "id", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "量表名称", prop: "title", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "单选题数量",
                prop: "radioCount",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "多选题数量",
                prop: "multiCount",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "判断题数量",
                prop: "judgeCount",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "创建时间", align: "center", prop: "createTime" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }