"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      loading: false,
      isDemo: this.$demo,
      postForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['siteData'])),
  mounted: function mounted() {
    // 演示账号密码
    if (this.isDemo) {
      this.postForm.username = 'admin';
      this.postForm.password = 'admin';
    }
  },
  methods: {
    loginBack: function loginBack() {
      // 其它跳到后台
      this.$router.push({
        path: '/admin/dashboard'
      });
      setTimeout(function () {
        this.loading = false;
      }, 1800);
    },
    accountLogin: function accountLogin() {
      var _this = this;
      this.$refs.postForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _this.$store.dispatch('user/login', _this.postForm).then(function () {
            _this.loginBack();
          }).catch(function () {
            _this.loading = false;
          });
        }
      });
    }
  }
};