"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _qu = require("@/api/qu/qu");
var _RepoSelect = _interopRequireDefault(require("@/components/RepoSelect"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'QuDetail',
  components: {
    FileUpload: _FileUpload.default,
    RepoSelect: _RepoSelect.default
  },
  data: function data() {
    return {
      quTypeDisabled: false,
      itemImage: true,
      levels: [{
        value: 1,
        label: '普通'
      }, {
        value: 2,
        label: '较难'
      }],
      quTypes: [{
        value: 1,
        label: '单选题'
      }, {
        value: 2,
        label: '多选题'
      }, {
        value: 3,
        label: '判断题'
      }],
      postForm: {
        repoIds: [],
        tagList: [],
        answerList: []
      },
      rules: {
        content: [{
          required: true,
          message: '题目内容不能为空！'
        }],
        quType: [{
          required: true,
          message: '题目类型不能为空！'
        }],
        level: [{
          required: true,
          message: '必须选择难度等级！'
        }],
        repoIds: [{
          required: true,
          message: '至少要选择一个量表！'
        }]
      }
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.quTypeDisabled = true;
      this.fetchData(id);
    }
  },
  methods: {
    handleTypeChange: function handleTypeChange(v) {
      this.postForm.answerList = [];
      if (v === 3) {
        this.postForm.answerList.push({
          isRight: true,
          content: '正确',
          analysis: ''
        });
        this.postForm.answerList.push({
          isRight: false,
          content: '错误',
          analysis: ''
        });
      }
      if (v === 1 || v === 2) {
        this.postForm.answerList.push({
          isRight: false,
          content: '',
          analysis: ''
        });
        this.postForm.answerList.push({
          isRight: false,
          content: '',
          analysis: ''
        });
        this.postForm.answerList.push({
          isRight: false,
          content: '',
          analysis: ''
        });
        this.postForm.answerList.push({
          isRight: false,
          content: '',
          analysis: ''
        });
      }
    },
    // 添加子项
    handleAdd: function handleAdd() {
      this.postForm.answerList.push({
        isRight: false,
        content: '',
        analysis: ''
      });
    },
    removeItem: function removeItem(index) {
      this.postForm.answerList.splice(index, 1);
    },
    fetchData: function fetchData(id) {
      var _this = this;
      (0, _qu.fetchDetail)(id).then(function (response) {
        _this.postForm = response.data;
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;
      console.log(JSON.stringify(this.postForm));
      var rightCount = 0;
      this.postForm.answerList.forEach(function (item) {
        if (item.isRight) {
          rightCount += 1;
        }
      });

      // if (this.postForm.quType === 1) {
      //   if (rightCount !== 1) {
      //     this.$message({
      //       message: '单选题答案只能有一个',
      //       type: 'warning'
      //     })
      //
      //     return
      //   }
      // }

      if (this.postForm.quType === 2) {
        if (rightCount < 2) {
          this.$message({
            message: '多选题至少要有两个正确答案！',
            type: 'warning'
          });
          return;
        }
      }
      if (this.postForm.quType === 3) {
        if (rightCount !== 1) {
          this.$message({
            message: '判断题只能有一个正确项！',
            type: 'warning'
          });
          return;
        }
      }
      this.$refs.postForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        (0, _qu.saveData)(_this2.postForm).then(function (response) {
          _this2.postForm = response.data;
          _this2.$notify({
            title: '成功',
            message: '试题保存成功！',
            type: 'success',
            duration: 2000
          });
          _this2.$router.push({
            name: 'ListQu'
          });
        });
      });
    },
    onCancel: function onCancel() {
      this.$router.push({
        name: 'ListQu'
      });
    }
  }
};