"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ExamTimer',
  props: {
    value: Number
  },
  data: function data() {
    return {
      leftSeconds: 0,
      min: '00',
      sec: '00'
    };
  },
  watch: {
    value: {
      handler: function handler() {
        this.leftSeconds = this.value;
        this.countdown();
      }
    }
  },
  created: function created() {
    this.leftSeconds = this.value;
  },
  methods: {
    // 进行倒计时
    countdown: function countdown() {
      // 倒计时结束了
      if (this.leftSeconds < 0) {
        this.$emit('timeout');
        return;
      }

      // 时
      var min = parseInt(this.leftSeconds / 60);
      var sec = parseInt(this.leftSeconds % 60);
      this.min = min > 9 ? min : '0' + min;
      this.sec = sec > 9 ? sec : '0' + sec;
      this.leftSeconds -= 1;
      var that = this;
      setTimeout(function () {
        that.countdown();
      }, 1000);
    }
  }
};