var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.server,
            accept: _vm.accept,
            "before-remove": _vm.beforeRemove,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "on-exceed": _vm.handleExceed,
            drag: _vm.listType !== "picture",
            limit: _vm.limit,
            headers: _vm.header,
            "file-list": _vm.fileList,
            "list-type": _vm.listType,
          },
          model: {
            value: _vm.fileUrl,
            callback: function ($$v) {
              _vm.fileUrl = $$v
            },
            expression: "fileUrl",
          },
        },
        [
          _vm.listType === "picture"
            ? _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ])
            : _vm._e(),
          _vm.listType !== "picture"
            ? _c("i", { staticClass: "el-icon-upload" })
            : _vm._e(),
          _vm.listType !== "picture"
            ? _c("div", { staticClass: "el-upload__text" }, [
                _vm._v(" 将文件拖到此处，或 "),
                _c("em", [_vm._v("点击上传")]),
              ])
            : _vm._e(),
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.tips))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }