"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteData = deleteData;
exports.fetchDetail = fetchDetail;
exports.fetchTree = fetchTree;
exports.pagingTree = pagingTree;
exports.saveData = saveData;
exports.sortData = sortData;
var _request = require("@/utils/request");
function pagingTree(data) {
  return (0, _request.post)('/exam/api/sys/depart/paging', data);
}
function fetchTree(data) {
  return (0, _request.post)('/exam/api/sys/depart/tree', data);
}
function fetchDetail(id) {
  var data = {
    id: id
  };
  return (0, _request.post)('/exam/api/sys/depart/detail', data);
}
function deleteData(ids) {
  var data = {
    ids: ids
  };
  return (0, _request.post)('/exam/api/sys/depart/delete', data);
}
function saveData(data) {
  return (0, _request.post)('/exam/api/sys/depart/save', data);
}
function sortData(id, sort) {
  var data = {
    id: id,
    sort: sort
  };
  return (0, _request.post)('/exam/api/sys/depart/sort', data);
}