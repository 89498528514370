var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticStyle: { "margin-top": "20px" } }, [
        _c("div", { staticClass: "qu-content" }, [
          _c("p", [
            _vm._v(
              "【" +
                _vm._s(_vm.quData.quType === 1 ? "单选题" : "多选题") +
                "】" +
                _vm._s(_vm.quData.content)
            ),
          ]),
          _vm.quData.image != null && _vm.quData.image != ""
            ? _c(
                "p",
                [
                  _c("el-image", {
                    staticStyle: { "max-width": "80%" },
                    attrs: { src: _vm.quData.image },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.quData.quType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.radioValues,
                        callback: function ($$v) {
                          _vm.radioValues = $$v
                        },
                        expression: "radioValues",
                      },
                    },
                    _vm._l(_vm.quData.answerList, function (an) {
                      return _c(
                        "el-radio",
                        { attrs: { label: an.id, readonly: "" } },
                        [_vm._v(_vm._s(an.content))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.quData.quType === 2
            ? _c(
                "div",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.multiValues,
                        callback: function ($$v) {
                          _vm.multiValues = $$v
                        },
                        expression: "multiValues",
                      },
                    },
                    _vm._l(_vm.quData.answerList, function (an) {
                      return _c("el-checkbox", { attrs: { label: an.id } }, [
                        _vm._v(_vm._s(an.content)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-card",
        { staticClass: "qu-analysis", staticStyle: { "margin-top": "20px" } },
        [
          _vm._v(" 整题解析： "),
          _c("p", [_vm._v(_vm._s(_vm.quData.analysis))]),
          !_vm.quData.analysis ? _c("p", [_vm._v("暂无解析内容！")]) : _vm._e(),
        ]
      ),
      _c(
        "el-card",
        {
          staticClass: "qu-analysis",
          staticStyle: { "margin-top": "20px", "margin-bottom": "30px" },
        },
        [
          _vm._v(" 选项解析： "),
          _vm._l(_vm.quData.answerList, function (an) {
            return an.analysis
              ? _c("div", { staticClass: "qu-analysis-line" }, [
                  _c("p", { staticStyle: { color: "#555" } }, [
                    _vm._v(_vm._s(an.content) + "："),
                  ]),
                  _c("p", { staticStyle: { color: "#1890ff" } }, [
                    _vm._v(_vm._s(an.analysis)),
                  ]),
                ])
              : _vm._e()
          }),
          _vm.analysisCount === 0
            ? _c("p", [_vm._v("暂无选项解析")])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-button",
        { attrs: { type: "info" }, on: { click: _vm.onCancel } },
        [_vm._v("返回")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }