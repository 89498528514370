var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("exam-select", {
                  staticClass: "filter-item",
                  model: {
                    value: _vm.listQuery.params.examId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "examId", $$v)
                    },
                    expression: "listQuery.params.examId",
                  },
                }),
                _c("depart-tree-select", {
                  staticClass: "el-select filter-item el-select--medium ",
                  attrs: {
                    options: _vm.treeData,
                    props: _vm.defaultProps,
                    width: "200px",
                  },
                  model: {
                    value: _vm.listQuery.params.departId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "departId", $$v)
                    },
                    expression: "listQuery.params.departId",
                  },
                }),
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    attrs: { placeholder: "测评状态", clearable: "" },
                    model: {
                      value: _vm.listQuery.params.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery.params, "state", $$v)
                      },
                      expression: "listQuery.params.state",
                    },
                  },
                  _vm._l(_vm.paperStates, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "测评名称", align: "center", prop: "title" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "ShowExam",
                                  params: { id: scope.row.id },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(scope.row.title) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "人员",
                    align: "center",
                    prop: "userId_dictText",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "部门",
                    align: "center",
                    prop: "departId_dictText",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "测评时长(分钟)",
                    align: "center",
                    prop: "totalTime",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.userTime) +
                              " / " +
                              _vm._s(scope.row.totalTime) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "测评得分", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.userScore) +
                              " / " +
                              _vm._s(scope.row.totalScore) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "测评时间",
                    align: "center",
                    prop: "createTime",
                    width: "180px",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "测评结果", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.state === 1
                            ? _c("span", [_vm._v("待阅卷")])
                            : scope.row.state === 0
                            ? _c("span", [_vm._v("待交卷")])
                            : _c("span", [
                                scope.row.userScore >= scope.row.qualifyScore
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#00ff00" } },
                                      [_vm._v("合格")]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "#ff0000" } },
                                      [_vm._v("不合格")]
                                    ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "测评状态", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("paperStateFilter")(scope.row.state)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "视频截图", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCapture(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("测评截图")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "测评截图",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        _vm._l(_vm.captureList, function (item) {
          return _c("img", {
            key: item.capture,
            staticStyle: { width: "230px" },
            attrs: { src: item.capture },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }