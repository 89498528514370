"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _exam = require("@/api/paper/exam");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      breakShow: false,
      breakId: '',
      openTypes: [{
        value: 1,
        label: '完全开放'
      }, {
        value: 2,
        label: '定向测评'
      }],
      listQuery: {
        current: 1,
        size: 10,
        params: {}
      },
      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/exam/api/exam/exam/online-paging'
      }
    };
  },
  created: function created() {
    this.check();
  },
  methods: {
    // 开始测评
    handlePre: function handlePre(examId) {
      this.$router.push({
        name: 'PreExam',
        params: {
          examId: examId
        }
      });
    },
    // 继续测评
    toExam: function toExam() {
      this.$router.push({
        name: 'StartExam',
        params: {
          id: this.breakId
        }
      });
    },
    // 检查进行中的测评
    check: function check() {
      var _this = this;
      (0, _exam.checkProcess)().then(function (res) {
        if (res.data && res.data.id) {
          _this.breakShow = true;
          _this.breakId = res.data.id;
        }
      });
    }
  }
};