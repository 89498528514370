"use strict";

var _interopRequireDefault = require("C:/tool/learn/other/exam-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataTable = _interopRequireDefault(require("@/components/DataTable"));
var _exam = require("@/api/exam/exam");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ExamList',
  components: {
    DataTable: _DataTable.default
  },
  data: function data() {
    return {
      openTypes: [{
        value: 1,
        label: '完全开放'
      }, {
        value: 2,
        label: '指定部门'
      }],
      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: ''
        }
      },
      options: {
        // 可批量操作
        multi: true,
        // 批量操作列表
        multiActions: [{
          value: 'delete',
          label: '删除'
        }, {
          value: 'enable',
          label: '启用'
        }, {
          value: 'disable',
          label: '禁用'
        }],
        // 列表请求URL
        listUrl: '/exam/api/exam/exam/paging',
        // 删除请求URL
        deleteUrl: '/exam/api/exam/exam/delete',
        // 删除请求URL
        stateUrl: '/exam/api/exam/exam/state',
        addRoute: 'AddExam'
      }
    };
  },
  methods: {
    exportWord: function exportWord(examId) {
      (0, _exam.exportWord)({
        name: 'exportWord',
        params: {
          examId: examId
        }
      });
    },
    handleExamDetail: function handleExamDetail(examId) {
      this.$router.push({
        name: 'ListExamUser',
        params: {
          examId: examId
        }
      });
    },
    handleUpdateExam: function handleUpdateExam(examId) {
      this.$router.push({
        name: 'UpdateExam',
        params: {
          id: examId
        }
      });
    }
  }
};