"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _qu = require("@/api/qu/qu");
var _book = require("@/api/user/book");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'BookTrain',
  data: function data() {
    return {
      examId: '',
      quId: '',
      tags: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
      analysisShow: false,
      quData: {},
      answerValues: [],
      rightValues: [],
      rightTags: []
    };
  },
  created: function created() {
    this.examId = this.$route.params.examId;
    this.fetchNextQu();
  },
  methods: {
    // 清理值
    clearValues: function clearValues() {
      this.answerValues = [];
      this.rightValues = [];
      this.analysisShow = false;
      this.rightTags = [];
    },
    // 查找测评详情
    fetchQuDetail: function fetchQuDetail(id) {
      var _this = this;
      // 当前赋值
      this.quId = id;
      this.clearValues();
      (0, _qu.fetchDetail)(id).then(function (response) {
        // 题目信息
        _this.quData = response.data;

        // 保存正确答案
        _this.quData.answerList.forEach(function (an, index) {
          an.abc = _this.tags[index];

          // 用户选定的
          if (an.isRight) {
            _this.rightValues.push(an.id);
            _this.rightTags.push(an.abc);
          }
        });
      });
    },
    fetchNextQu: function fetchNextQu() {
      var _this2 = this;
      // 查找下一个
      (0, _book.nextQu)(this.examId, this.quId).then(function (response) {
        _this2.fetchQuDetail(response.data.id);
      });
    },
    onCancel: function onCancel() {
      // this.$router.push({ name: 'ListTran' })
      this.$router.push({
        name: 'BookList'
      });
    },
    handNext: function handNext() {
      // 直接显示下一个
      if (this.analysisShow) {
        // 正确显示下一个
        this.fetchNextQu();
      } else {
        // 直接判断正确性
        if (this.rightValues.join(',') === this.answerValues.join(',')) {
          this.$message({
            message: '回答正确，你好棒哦！',
            type: 'success'
          });

          // 正确显示下一个
          this.fetchNextQu();
        } else {
          // 错误显示解析
          this.analysisShow = true;
          this.$message({
            message: '很遗憾，做错了呢，请参考解析！',
            type: 'error'
          });
        }
      }
    }
  }
};