var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticStyle: { color: "#ff0000", "font-weight": "700" } },
    [_vm._v(_vm._s(_vm.min) + "分钟" + _vm._s(_vm.sec) + "秒")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }