var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户名" } },
        [
          _c("el-input", {
            attrs: { readonly: "" },
            model: {
              value: _vm.user.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "密码" } },
        [
          _c("el-input", {
            attrs: { type: "password", placeholder: "不修改请留空" },
            model: {
              value: _vm.user.password,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("修改")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }