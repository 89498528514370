"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _exam = require("@/api/paper/exam");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      // 测评ID
      paperId: '',
      paperData: {
        quList: []
      },
      radioValues: {},
      multiValues: {},
      radioRights: {},
      multiRights: {},
      myRadio: {},
      myMulti: {}
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.paperId = id;
      this.fetchData(id);
    }
  },
  methods: {
    fetchData: function fetchData(id) {
      var _this = this;
      var params = {
        id: id
      };
      (0, _exam.paperResult)(params).then(function (response) {
        // 测评内容
        _this.paperData = response.data;

        // 填充该题目的答案
        _this.paperData.quList.forEach(function (item) {
          var radioValue = '';
          var radioRight = '';
          var myRadio = '';
          var multiValue = [];
          var multiRight = [];
          var myMulti = [];
          item.answerList.forEach(function (an) {
            // 用户选定的
            if (an.checked) {
              if (item.quType === 1 || item.quType === 3) {
                radioValue = an.id;
                myRadio = an.abc;
              } else {
                multiValue.push(an.id);
                myMulti.push(an.abc);
              }
            }

            // 正确答案
            if (an.isRight) {
              if (item.quType === 1 || item.quType === 3) {
                radioRight = an.abc;
              } else {
                multiRight.push(an.abc);
              }
            }
          });
          _this.multiValues[item.id] = multiValue;
          _this.radioValues[item.id] = radioValue;
          _this.radioRights[item.id] = radioRight;
          _this.multiRights[item.id] = multiRight;
          _this.myRadio[item.id] = myRadio;
          _this.myMulti[item.id] = myMulti;
        });
        console.log(_this.multiValues);
        console.log(_this.radioValues);
      });
    }
  }
};