"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _qu = require("@/api/qu/qu");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'QuView',
  data: function data() {
    return {
      quData: {},
      radioValues: '',
      multiValues: [],
      analysisCount: 0
    };
  },
  created: function created() {
    var id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.fetchData(id);
    }
  },
  methods: {
    fetchData: function fetchData(id) {
      var _this = this;
      (0, _qu.fetchDetail)(id).then(function (response) {
        _this.quData = response.data;
        _this.quData.answerList.forEach(function (an) {
          // 解析数量
          if (an.analysis) {
            _this.analysisCount += 1;
          }

          // 用户选定的
          if (an.isRight) {
            if (_this.quData.quType === 1) {
              _this.radioValues = an.id;
            } else {
              _this.multiValues.push(an.id);
            }
          }
        });
      });
    },
    onCancel: function onCancel() {
      this.$router.push({
        name: 'ListTran'
      });
    }
  }
};